<template>
  <div class="height-100 max_box">
      <div class="audio_box none">
      <audio src="https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3" ref="bgAudio"></audio>
       <audio :src="audioSrcList[0]" ref="audio1"></audio>
      <audio :src="audioSrcList[1]" ref="audio2"></audio>
      <audio :src="audioSrcList[2]" ref="audio3"></audio>
      <audio :src="audioSrcList[3]" ref="audio4"></audio>
      <audio :src="audioSrcList[4]" ref="audio5"></audio>
    </div>
    <div class="animate__animated animate__fadeIn duration" id="card1">
      <div class="logo_nt">
        <img  src="../../assets/img/ct.png" alt="" />
      </div>
      <div>
        <div class="time_title">{{ formatToDate(extra_param_2[0], "-") }}</div>
        <div class="title">融资融券视点</div>
        <!-- <div class="mb-16">
          融资追击:<span class="title_gold">今日最佳持股策略</span>
        </div> -->
        <!-- <div class="title_bottom">关注“灯塔融强”，掌握两融最全动向</div> -->
      </div>
      <div class="foot">
        <p>官方客服电话：95399</p>
        <p>股市有风险 投资需谨慎 请理性使用融资融券工具</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card2"
    >
      <div class="logo_nt">
        <img src="../../assets/img/ct.png" alt="" />
      </div>
      <div class="content_box">
        <div class="title">
          <p class="title_l">
            两融趋势
            <span>{{ formatToDate(extra_param_2[0], ".") }}</span>
          </p>
        </div>
        <p class="desc">
          <span>{{ extra_param_1[0] }}</span
          >，两市融资融券余额<span class="color_gold num_width" id="num1"
            >{{ extra_param_1[1] }} </span
          ><span class="color_gold">亿</span>，较上日<span>{{
            extra_param_1[2]
          }}</span>
          <span
            :class="{
              color_gold: extra_param_1[2] === '上涨',
              color_green: extra_param_1[2] === '下降',
            }"
            >{{ extra_param_1[3] }}%</span
          >,融资买入<span id="num2" class="color_gold num_width2">{{
            extra_param_1[4]
          }}</span
          ><span class="color_gold">亿</span>，较上日{{ extra_param_1[5]
          }}<span
            :class="{
              color_gold: extra_param_1[5] === '上涨',
              color_green: extra_param_1[5] === '下降',
            }"
            >{{ extra_param_1[6] }}％</span
          >
        </p>
        <div class="title">
          <p class="title_1">
            {{ chart_data_1[0] && chart_data_1[0].model_chart_name }}（亿元）
          </p>
        </div>
        <div id="chart1"></div>
        <div class="title">
          <p class="title_1">
            {{ chart_data_2[0] && chart_data_2[0].model_chart_name }}（亿元）
          </p>
        </div>
        <div id="chart1_2"></div>
      </div>
      <div class="foot">
        <p>官方客服电话：95399</p>
        <p>股市有风险 投资需谨慎 请理性使用融资融券工具</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card3"
    >
      <div class="logo_nt">
         <img  src="../../assets/img/ct.png" alt="" />
      </div>
      <div class="content_box">
        <div class="title">
          <p class="title_l">行业板块风云榜/融资净买入额</p>
          <p class="title_r_1">{{ formatToDate(extra_param_2[0], ".") }}</p>
        </div>
        <div class="rank_list">
          <ul>
            <li
              v-for="(item, index) in ccb1"
              :key="index"
              :class="`card-li-${Math.floor(index / 4)}`"
            >
              <div class="text1">{{ item }}</div>
              <div class="text2">{{ udfs[index] }}亿</div>
            </li>
          </ul>
        </div>
        <div class="slider">
          <span>低</span>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <span>高</span>
        </div>
        <div class="tips">声明：本页面行业数据来源于灯塔财经</div>
      </div>
      <div class="foot">
        <p>官方客服电话：95399</p>
        <p>股市有风险 投资需谨慎 请理性使用融资融券工具</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card4"
    >
      <div class="logo_nt">
        <img  src="../../assets/img/ct.png" alt="" />
      </div>
      <div class="content_box">
        <div class="title">
          <p class="title_l">{{ model_chart_name_4 }}</p>
          <p class="title_r_1">
            <span
              ><i>{{ formatToDate(extra_param_2[0], ".") }}</i></span
            >
          </p>
        </div>
        <p class="desc">
          从个券来看，<span>{{ extra_param_1[0] }}</span
          >融资净买入额最多的个股为{{ extra_param_3[1] }}，融资净买入额为
          <span class="color_gold" id="num3">{{ extra_param_3[2] }}</span
          >亿
        </p>
        <div class="desc_title_wrapper">
          <div class="desc_title1">融资净买入额（亿元）</div>
          <div class="desc_title2">涨跌幅%</div>
        </div>
        <div class="desc_values_wrapper">
          <div
            class="desc_values_item"
            v-for="(value, index) in chart4.change"
            :key="index"
          >
            {{ value }}
          </div>
        </div>
        <div id="chart4"></div>
        <div class="tips">声明：本视频内个股数据均来自沪深市场</div>
      </div>
      <div class="foot">
        <p>官方客服电话：95399</p>
        <p>股市有风险 投资需谨慎 请理性使用融资融券工具</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card5"
    >
      <div class="logo_nt">
         <img src="../../assets/img/ct.png" alt="" />
      </div>
      <div class="content_box">
        <div class="title">
          <p class="title_l">{{ model_chart_name_5 }}</p>
          <p class="title_r_1">
            <span
              ><i>{{ formatToDate(extra_param_2[0], ".") }}</i></span
            >
          </p>
        </div>
        <p class="desc">
          从个券来看，<span>{{ extra_param_1[0] }}</span
          >融资净偿还最多的个股为{{ extra_param_4[1] }}，融资净偿还额为<span
            class="color_gold"
            id="num4"
            >{{ extra_param_4[2] }}</span
          >亿
        </p>
        <div class="desc_title_wrapper">
          <div class="desc_title1">融资净偿还额（亿元）</div>
          <div class="desc_title2">涨跌幅%</div>
        </div>
        <div class="desc_values_wrapper">
          <div
            class="desc_values_item"
            v-for="(value, index) in chart5.change"
            :key="index"
          >
            {{ value }}
          </div>
        </div>
        <div id="chart5"></div>
        <div class="tips">声明：本视频内个股数据均来自沪深市场</div>
      </div>
      <div class="foot">
        <p>官方客服电话：95399</p>
        <p>股市有风险 投资需谨慎 请理性使用融资融券工具</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card6"
    >
      <div class="logo_nt">
         <img  src="../../assets/img/ct.png" alt="" />
      </div>
      <div class="content_box">
        <div class="title">
          <p class="title_l">{{ model_chart_name_6 }}</p>
          <p class="title_r_1">
            <span
              ><i>{{ formatToDate(extra_param_2[0], ".") }}</i></span
            >
          </p>
        </div>
        <div class="desc_title_wrapper">
          <div class="desc_title1">净买入额占比%</div>
          <div class="desc_title2">净买入额(亿元)</div>
          <div class="desc_title3">涨跌幅</div>
        </div>
        <div class="desc_values_wrapper">
          <div
            class="desc_values_item"
            v-for="(value, index) in chart6.change"
            :key="index"
          >
            <span class="desc_values_width1">{{ value }}%</span>
            <span class="desc_values_width">{{ chart6.buy_in[index] }}</span>
          </div>
        </div>
        <div id="chart6"></div>
        <div class="tips">声明：本视频内个股数据均来自沪深市场</div>
      </div>
      <div class="foot">
        <p>官方客服电话：95399</p>
        <p>股市有风险 投资需谨慎 请理性使用融资融券工具</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card7"
    >
      <div class="logo_nt">
         <img  src="../../assets/img/ct.png" alt="" />
      </div>
      <div class="content_box">
        <div class="title1">风险提示</div>
        <div class="text1">
          融资融券业务属于中高风险业务，请确保您的风险承受能力等级符合该业务风险等级匹配要求。建议您审慎评估该业务特征及风险，自行做出投资决定，并承担相应风险。
        </div>
        <div class="line"></div>
        <div class="title2">免责声明</div>
        <div class="text2">
          本系列视频内容不构成任何投资意见及建议，视频所涉及相关信息均源自于市场公开渠道，本公司对这些信息的准确性或完整性不作保证，亦不对因使用该等信息而引发或可能引发的损失承担任何责任。本系列视频最终解释权归诚通证券股份有限公司所有。
        </div>
      </div>
      <div class="foot">
        <p>官方客服电话：95399</p>
        <p>股市有风险 投资需谨慎 请理性使用融资融券工具</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card8"
    >
      <div class="logo">
        <img class="none" src="../../assets/img/ct1.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { GetParams, formatToDate, GetParams2 } from "../../utils/common";
import ApiServe from "@/api/index";
import { sleep } from "@/utils/common";
import { initRecordController, startRecord, stopRecord } from "dt-record-controller";
export default {
  name: "NewTimes",
  data() {
    return {
      audioSrcList: [],
      audioTimeList: [],
      device_id: null,
      options: {
        useEasing: true, // 过渡动画效果，默认ture
        useGrouping: false, // 千分位效果，例：1000->1,000。默认true
        //separator: ',',   // 使用千分位时分割符号
        decimals: 2, // 小数位分割符号
        prefix: "", // 前置符号
        suffix: "", // 后置符号，可汉字
      },
      extra_param_1: [],
      extra_param_2: [],
      extra_param_3: [],
      extra_param_4: [],
      chart_data_1: [],
      chart_data_2: [],
      chart_data_3: [],
      chart_data_4: [],
      chart_data_5: [],
      chart_data_6: [],
      chart_data_7: [],
      model_chart_name: "",
      model_chart_name_4: "",
      model_chart_name_5: "",
      model_chart_name_6: "",
      chart7_data: [],
      udfs: "",
      ccb1: "",
      buy_in: [],
      min_chart1: "",
      max_chart1: "",
      min_chart1_2: "",
      max_chart1_2: "",
      chart1: {
        x: [],
        y: [],
      },
      chart1_2: {
        x: [],
        y: [],
      },
      chart4: { sec_name: [], change: [], buy_in: [] },
      chart5: { sec_name: [], change: [], buy_in: [] },
      chart6: { sec_name: [], change: [], buy_in: [], ym: [] },
      chart7: {
        iHoldDays: [],
        sBuyPercent: [],
        fWinPer: [],
        fIncRateAvg: [],
        sSecName: [],
        sDtSecCode: [],
      },
      recordtimeAll: null,
      // device_id:null,
      id: "",
      model_id: null,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
      audioData: [],
    };
  },
  created() {
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 1;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
    this.getData();
    this.getAudio();
    initRecordController()
  },
  computed: {
    allReady() {
      const { extra_param_1, audioSrcList } = this;
      return {
        extra_param_1,
        audioSrcList,
      };
    },
    // newIpPort() {
    //   if (this.source == "ali") {
    //     return "https://recordvideoali.wedengta.com";
    //   } else {
    //     // return "http://172.20.10.5:9000";//这里改成本机电脑ip
    //     return "https://recordvideo.wedengta.com";
    //   }
    // },
  },
  watch: {
    allReady(val) {
      if (val.extra_param_1.length > 0 && val.audioSrcList.length > 0) {
          startRecord()
          this.load();
        // if (this.ip && this.port) {
        //   const params = {
        //     ip: this.ip,
        //     port: this.port,
        //     offset_x: this.offset_x,
        //     offset_y: this.offset_y,
        //     width: this.width,
        //     height: this.height,
        //   };
        //   ApiServe.recordstartPc(this.newIpPort, params)
        //     .then(({ code }) => {})
        //     .catch((err) => {
        //       ApiServe.recordreleasePc(this.newIpPort, {
        //         ip: this.ip,
        //         port: this.port,
        //       });
        //     });
        //   this.load();
        //   startRecord()
        // } else {
        //   // alert("没获取到ip,port");
        //   this.load();
        //   startRecord()  
        // }
      }
    },
  },
  methods: {
    formatToDate,
    getData() {
      let params = {
        model_id: 75,
      };
      if (this.url_extra_params) {
        params = {
          model_id: 75,
          url_extra_params: this.url_extra_params,
        };
      }
      ApiServe.getDataPC(params)
        .then(({ data, code }) => {
          if (code == 200) {
            this.extra_param_1 = data.extra_param_1;
            this.chart_data_1 = data.chart_data_1;
            this.chart_data_2 = data.chart_data_2;
            this.chart_data_3 = data.chart_data_3[0];
            this.ccb1 = this.chart_data_3.data["ccb1"].reverse();
            this.udfs =
              this.chart_data_3.data["udfs.round2(dwwc, 2)"].reverse();

            this.chart1 = {
              x: data.chart_data_1[0].data.x,
              y: data.chart_data_1[0].data.y,
            };
            this.max_chart1 = Math.max.apply(null, this.chart1.y);
            this.min_chart1 = Math.min.apply(null, this.chart1.y);

            this.chart1_2 = {
              x: data.chart_data_2[0].data.x,
              y: data.chart_data_2[0].data.y,
            };
            this.max_chart1_2 = Math.max.apply(null, this.chart1_2.y);
            this.min_chart1_2 = Math.min.apply(null, this.chart1_2.y);

            // chart4
            const chart4Data = data.chart_data_4[0].data;
            this.chart4 = {
              sec_name: chart4Data.sec_name.slice(0, 10),
              change: chart4Data["涨跌幅"].slice(0, 10),
              buy_in: chart4Data["融资净买入额"].slice(0, 10),
            };
            this.model_chart_name_4 = data.chart_data_4[0].model_chart_name;

            // chart5
            const chart5Data = data.chart_data_5[0].data;
            this.chart5 = {
              sec_name: chart5Data.sec_name.slice(0, 10),
              change: chart5Data["涨跌幅"].slice(0, 10),
              buy_in: chart5Data["融资净买入额"].slice(0, 10),
            };
            this.model_chart_name_5 = data.chart_data_5[0].model_chart_name;

            // chart6
            const chart6Data = data.chart_data_6[0].data;
            this.chart6 = {
              sec_name: chart6Data.sec_name || [],
              change: chart6Data["涨跌幅"] || [],
              ym: chart6Data["ym"] || [], // %
              buy_in: chart6Data["融资净买入额"] || [],
            };
            this.model_chart_name_6 = data.chart_data_6[0].model_chart_name;

            //card 7

            this.extra_param_2 = data.extra_param_2;
            this.extra_param_3 = data.extra_param_3;
            this.extra_param_4 = data.extra_param_4;

            // if (this.extra_param_1.length == 0 && this.ip) {
            //   ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
            // }
          }
        })
        // .catch((err) => {
        //   if (this.ip) {
        //     ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
        //   }
        // });
    },
    //获取股票名称
    async getGp(url, params) {
      params = this.chart7.sDtSecCode;
      // console.log(this.chart7.sDtSecCode);
      // console.log(params);
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(
        JSON.stringify({ vDtSecCode: params })
      );
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      let { content, ret } = await this.$axios.get(newUrl);

      if (ret == 0) {
        // console.log(content);
        const vSecSimpleQuote = JSON.parse(content).vSecSimpleQuote;
        vSecSimpleQuote.map((item) => {
          item.sSecName;
          this.chart7.sSecName.push(item.sSecName);
        });
      }
    },

    // 获取旁白
    getAudio() {
      let params = {
        model_id: 75,
      };
      if (this.url_extra_params) {
        params = {
          model_id: 75,
          url_extra_params: this.url_extra_params,
        };
      }
      ApiServe.getVideoDataPC(params)
        .then(({ data, code }) => {
          if (code == 200) {
            this.audioData = data.slice(0, 5) || [];
            this.audioSrcList = data.map((item) => {
              return item.audio_link;
            });
            this.audioSrcList = this.audioSrcList.slice(0, 5);
            this.audioTimeList = data.map((item) => {
              return item.scene_duration;
            });
            this.audioTimeList = this.audioTimeList.slice(0, 5);
            this.recordtimeAll =
              this.audioTimeList.reduce(function (prev, curr, idx, arr) {
                return prev + curr;
              }, 0) +
              this.audioTimeList.length +
              5;
            // console.log(this.audioSrcList);
            // console.log(this.audioTimeList);
            // console.log(this.recordtimeAll);
            // console.log(this.audioData);
            // if (this.audioSrcList.length == 0 && this.ip) {
            //   ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
            // }
          }
        })
        // .catch((err) => {
        //   if (this.ip) {
        //     ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
        //   }
        // });
    },
    // 数字滚动
    startNumber(vdom, number, point) {
      let num = new CountUp(vdom, 0, number, point, 4, this.options);
      num.start();
    },
    // 融资融券余额（亿元）
    loadChart1() {
      let myChart = this.$echarts.init(document.getElementById("chart1"));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.chart1.x,
          axisTick: {
            show: false, //y轴坐标点消失
          },
          axisLabel: {
            align: "right",
            textStyle: {
              fontSize: 19,
              color: "#FFD2AC",
            },
            showMaxLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: "#FFD2AC",
              width: 0.5,
            },
          },
        },
        legend: {
          data: [],
          left: 0,
        },
        animationDuration: 4000,
        grid: {
          top: 13,
          left: -25,
          right: 60,
          bottom: 27,
          show: true,
          borderWidth: "0.5",
          color: ["#FFD2AC"],
        },
        yAxis: [
          {
            type: "value",
            name: "",
            boundaryGap: [0, "100%"],
            position: "right",
            min: this.min_chart1,
            max: this.max_chart1,
            axisLabel: {
              textStyle: {
                fontSize: 16,
                color: "#FFD2AC",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: ["#FFD2AC"],
              },
            },
            axisLine: {
              lineStyle: {
                color: "#FFD2AC",
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            type: "line",
            smooth: 0.6,
            symbol: "none",
            lineStyle: {
              color: "#FFBB81",
              width: 2,
            },
            color: "#F68630",
            data: this.chart1.y,
            label: {
              show: true,
            },
            areaStyle: {
              background:
                "linear-gradient(180deg, #FFD2AC 0%, rgba(255, 210, 172, 0) 100%)",
            },
          },
        ],
        markLine: {
          symbol: ["none", "none"],
          label: { show: false },
          data: [{ xAxis: 1 }, { xAxis: 3 }, { xAxis: 5 }, { xAxis: 7 }],
        },
      });
    },
    // 融资买入额 （亿元）
    loadChart1_2() {
      let myChart = this.$echarts.init(document.getElementById("chart1_2"));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.chart1_2.x,
          axisTick: {
            show: false, //y轴坐标点消失
          },
          axisLabel: {
            align: "right",
            textStyle: {
              fontSize: 19,
              color: "#FFD2AC",
            },
            showMaxLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: "#FFD2AC",
              width: 0.5,
            },
          },
        },
        legend: {
          data: [],
          left: 0,
        },
        animationDuration: 4000,
        grid: {
          top: 13,
          left: -25,
          right: 60,
          bottom: 25,
          show: true,
          borderWidth: "0.5",
          color: ["#FFD2AC"],
        },
        yAxis: [
          {
            type: "value",
            name: "",
            boundaryGap: [0, "100%"],
            position: "right",
            min: this.min_chart1_2,
            max: this.max_chart1_2,
            axisLabel: {
              textStyle: {
                fontSize: 16,
                color: "#FFD2AC",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: ["#FFD2AC"],
              },
            },
            axisLine: {
              lineStyle: {
                color: "#FFD2AC",
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            type: "line",
            smooth: 0.6,
            symbol: "none",
            lineStyle: {
              color: "#FFBB81",
              width: 2,
            },
            color: "#F68630",
            data: this.chart1_2.y,
            label: {
              show: true,
            },
            areaStyle: {
              background:
                "linear-gradient(180deg, #FFD2AC 0%, rgba(255, 210, 172, 0) 100%)",
            },
          },
        ],
        markLine: {
          symbol: ["none", "none"],
          label: { show: false },
          data: [{ xAxis: 1 }, { xAxis: 3 }, { xAxis: 5 }, { xAxis: 7 }],
        },
      });
    },

    // 融资净买入
    loadChart4() {
      var myChart = this.$echarts.init(document.getElementById("chart4"));
      myChart.setOption({
        grid: {
          left: "120",
          right: "120",
          bottom: "0",
          top: 0,
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            max: 10,
            show: true,
            axisLine: { show: false },
            splitLine: { show: false },
          },
        ],
        yAxis: [
          {
            inverse: true,
            type: "category",
            data: this.chart4["sec_name"],
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
              margin: 120,
              textStyle: {
                fontSize: 24,
                color: "#FFDCBE",
                align: "left",
              },
              formatter: function (params) {
                var maxLength = 5;
                //判断长度，超出使用...代替
                if (params && params.length > maxLength) {
                  return params.substring(0, maxLength - 1) + "...";
                } else {
                  return params;
                }
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: this.chart4.buy_in,
            barWidth: 26,
            backgroundStyle: {
              color: "#93161D",
            },
            itemStyle: {
              normal: {
                color: "#FFDCBE",
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#FFDCBE",
                    fontSize: 20,
                  },
                },
              },
            },
            showBackground: true,
          },
        ],
      });
    },
    // 融资净卖出
    loadChart5() {
      var myChart = this.$echarts.init(document.getElementById("chart5"));
      myChart.setOption({
        grid: {
          left: "120",
          right: "80",
          bottom: "0",
          top: 0,
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            max: 10,
            show: true,
            axisLine: { show: false },
            splitLine: { show: false },
          },
        ],
        yAxis: [
          {
            type: "category",
            inverse: true,
            data: this.chart5["sec_name"],
            axisLine: { show: false, onZero: true },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
              margin: 120,
              textStyle: {
                fontSize: 24,
                color: "#FFDCBE",
                align: "left",
              },
              formatter: function (params) {
                var maxLength = 5;
                //判断长度，超出使用...代替
                if (params && params.length > maxLength) {
                  return params.substring(0, maxLength - 1) + "...";
                } else {
                  return params;
                }
              },
            },
          },
        ],
        series: [
          {
            inverse: true,
            type: "bar",
            data: this.chart5.buy_in.map((item) => {
              return Math.abs(item);
            }),
            barWidth: 26,
            backgroundStyle: {
              color: "#93161D",
            },
            itemStyle: {
              normal: {
                color: "#FFDCBE",
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  formatter: function (value) {
                    return value.data;
                  },
                  textStyle: {
                    //数值样式
                    color: "#FFDCBE",
                    fontSize: 20,
                  },
                },
              },
            },
            showBackground: true,
          },
        ],
      });
    },
    // 行业盈利与估值对比散点图
    loadChart6() {
      var myChart = this.$echarts.init(document.getElementById("chart6"));
      myChart.setOption({
        grid: {
          left: "120",
          right: "220",
          bottom: "0",
          top: 0,
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            max: 100,
            show: true,
            axisLine: { show: false },
            splitLine: { show: false },
          },
        ],
        yAxis: [
          {
            inverse: true,
            type: "category",
            data: this.chart6["sec_name"],
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
             axisLabel: {
              margin: 120,
              textStyle: {
                fontSize: 24,
                color: "#FFDCBE",
                align: "left",
              },
              formatter: function (params) {
                var maxLength = 5;
                //判断长度，超出使用...代替
                if (params && params.length > maxLength) {
                  return params.substring(0, maxLength - 1) + "...";
                } else {
                  return params;
                }
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: this.chart6.ym,
            barWidth: 27,

            backgroundStyle: {
              color: "#93161D",
            },
            itemStyle: {
              normal: {
                color: "#FFDCBE",
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#FFDCBE",
                    fontSize: 20,
                  },
                },
              },
            },
            showBackground: true,
          },
        ],
      });
    },
    endRecord() {
      let arr = [
        2,
        3 + this.audioTimeList[0],
        4 + this.audioTimeList[0] + this.audioTimeList[1],
        5 +
          this.audioTimeList[0] +
          this.audioTimeList[1] +
          this.audioTimeList[2],
        6 +
          this.audioTimeList[0] +
          this.audioTimeList[1] +
          this.audioTimeList[2] +
          this.audioTimeList[3],
        // 7 +
        //   this.audioTimeList[0] +
        //   this.audioTimeList[1] +
        //   this.audioTimeList[2] +
        //   this.audioTimeList[3] +
        //   this.audioTimeList[4],
        // 8 +
        //   this.audioTimeList[0] +
        //   this.audioTimeList[1] +
        //   this.audioTimeList[2] +
        //   this.audioTimeList[3] +
        //   this.audioTimeList[4] +
        //   this.audioTimeList[5],
      ];
      let audiosOrderList = this.audioData.map((item, index) => {
        return {
          audioLink: item.audio_link,
          timeIndex: arr[index],
          audioLen: item.audio_length,
        };
      });
      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: this.recordtimeAll,
        videmBgm:
          "https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3",
        audiosOrderList: audiosOrderList,
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },

    load() {
      this.$refs.bgAudio.volume = 0.5
      this.$refs.bgAudio.play();
      setTimeout(() => {
        $("#card1").addClass("animate__fadeOut");
      }, 1000);

      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        this.$refs.audio1.play();
        this.startNumber("num1", this.extra_param_1[1], 2);
        this.startNumber("num2", this.extra_param_1[4], 2);
        this.loadChart1();
        this.loadChart1_2();
      }, 2000); //间隔

      setTimeout(() => {
        $("#card2").addClass("animate__fadeOut");
      }, (2 + this.audioTimeList[0]) * 1000);

      setTimeout(() => {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        this.$refs.audio2.play();
      }, (3 + this.audioTimeList[0]) * 1000); //间隔

      setTimeout(() => {
        $("#card3").addClass("animate__fadeOut");
      }, (3 + this.audioTimeList[0] + this.audioTimeList[1]) * 1000);

      setTimeout(() => {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        this.$refs.audio3.play();
        this.startNumber("num3", this.extra_param_3[1], 2);
        this.loadChart4();
      }, (4 + this.audioTimeList[0] + this.audioTimeList[1]) * 1000); //间隔

      setTimeout(() => {
        $("#card4").addClass("animate__fadeOut");
      }, (4 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2]) * 1000);

      setTimeout(() => {
        $("#card4").addClass("none");
        $("#card5").removeClass("none");
        this.$refs.audio4.play();
        this.startNumber("num4", this.extra_param_4[1], 2);
        this.loadChart5();
      }, (5 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2]) * 1000);

      //间隔

      setTimeout(() => {
        $("#card5").addClass("animate__fadeOut");
      }, (5 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3]) * 1000);

      setTimeout(() => {
        $("#card5").addClass("none");
        $("#card6").removeClass("none");
        this.$refs.audio5.play();
        this.loadChart6();
      }, (6 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3]) * 1000); //间隔

      setTimeout(() => {
        $("#card6").addClass("animate__fadeOut");
      }, (6 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + this.audioTimeList[4]) * 1000);

      setTimeout(() => {
        $("#card6").addClass("none");
        $("#card7").removeClass("none");
        // this.$refs.audio6.play();
        // console.log(this.audioTimeList);
      }, (7 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + this.audioTimeList[4]) * 1000);

      setTimeout(() => {
        $("#card7").addClass("animate__fadeOut");
      }, (8 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + this.audioTimeList[4]) * 1000);

      setTimeout(() => {
        $("#card7").addClass("none");
        $("#card8").removeClass("none");
        $("#card8 .logo img").removeClass("none");
        $("#card8 .logo img").addClass("animate__flash");
        // this.$refs.audio7.play();
      }, (9 + this.audioTimeList[0] + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + this.audioTimeList[4]) * 1000);
      // 总时间
      // if (this.ip) {
        setTimeout(() => {
          // this.endRecord();
          console.log(this.recordtimeAll);
          stopRecord();
        }, (this.recordtimeAll + 2) * 1000);
      // }
    },
  },
};
</script>
<style scoped lang="less">
* {
  box-sizing: border-box;
  li {
    list-style: none;
  }
}
.none {
  display: none;
}
.max_box {
  background: url("../../assets/img/bg_nt.png") no-repeat center center;
  background-size: cover;
  font-size: 12px;
}
.logo_nt {
  position: absolute;
  top: 50px;
  right: 45px;
  width: 278px;
  height: 72px;
  img{
    width: 100%;
    height: 100%;
  }
}

.logo-box {
  width: 78px;
  opacity: 1;
}
.card {
  background: url("../../assets/img/bg_nt.png") no-repeat center center;
  background: none;
  color: #7e8090;
  font-size: 12px;
  overflow: hidden;
  padding: 133px 13px;
  box-sizing: border-box;
  .content_box {
    background: #c11a24;
    width: 100%;
    height: 1018px;
    box-shadow: 0px 12px 32px rgba(119, 72, 59, 0.16);
    border-radius: 24px;
    border: 6px solid #e16c3d;
    padding: 42px 27px;
    position: relative;
  }
  .foot {
    position: absolute;
    bottom: 40px;
    left: 127px;
    text-align: center;
    font-size: 22px;
    color: #ffdcbe;
    opacity: 0.58;
    p:first-of-type {
      margin-bottom: 7px;
    }
  }
}
.color_EF622E {
  color: #ef622e;
}

.color_gold {
  color: #ffe034;
}
.color_green {
  color: #43de9c;
}

.ab_center {
  position: absolute;
  left: 16px;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
}
#card1 {
  background: url("../../assets/img/bg_nt1.png") no-repeat center center;
  background-size: cover;
  .time_title {
    width: 100%;
    font-size: 41px;
    position: absolute;
    top: 638px;
    left: 256px;
    color: #ffffff;
    letter-spacing: 2px;
  }
  .title {
    font-size: 76px;
    font-weight: 500;
    color: #ffdcbe;
    position: absolute;
    top: 476px;
    left: 140px;
  }
  .title_bottom {
    position: absolute;
    bottom: 359px;
    width: 100%;
    color: #ffd2ac;
    font-size: 24px;
  }
  .foot {
    position: absolute;
    bottom: 40px;
    left: 127px;
    text-align: center;
    font-size: 22px;
    color: #ffdcbe;
    opacity: 0.58;
    p:first-of-type {
      margin-bottom: 7px;
    }
  }
}

#card3 {
  .title_l {
    font-size: 35px !important;
  }
}
#card4,
#card5,
#card6 {
  .tips {
    bottom: 25px !important;
    width: 515px !important;
  }
}
.title_gold {
  color: #ffe034;
  font-size: 40px;
  margin-left: 16px;
}

.num_width {
  display: inline-block;
  width: 140px;
  text-align: center;
}
.num_width2 {
  display: inline-block;
  width: 100px;
  text-align: center;
}

@keyframes rowup {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -386px, 0);
    transform: translate3d(0, -386px, 0);
    display: none;
  }
}
.rank_list {
  margin-top: 8px;
  width: 100%;
  margin-bottom: 45px;
  max-height: 600px;
  overflow: hidden;
  ul {
    width: 100%;
    display: flex;
    //justify-content: space-between;
    flex-wrap: wrap;
    animation: rowup 5s infinite;

    li {
      width: 23%;
      height: 107px;
      background: #ffd2ac;
      opacity: 1;
      border-radius: 12px;
      margin-bottom: 16px;
      margin-right: 2%;
      text-align: center;
      padding-top: 16px;
      box-sizing: border-box;
      .text1 {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #c11a24;
        opacity: 1;
        margin-bottom: 4px;
      }
      .text2 {
        font-size: 27px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #c11a24;
        opacity: 1;
      }
    }
    .card-li-0 {
      opacity: 1;
    }
    .card-li-1 {
      opacity: 0.9;
    }
    .card-li-2 {
      opacity: 0.8;
    }
    .card-li-3 {
      opacity: 0.7;
    }
    .card-li-4 {
      opacity: 0.6;
    }
    .card-li-5 {
      opacity: 0.5;
    }
    .card-li-6 {
      opacity: 0.4;
    }
    .card-li-7 {
      opacity: 0.3;
    }
    .card-li-8 {
      opacity: 0.2;
    }
  }
}
.slider {
  font-size: 24px;
  color: #747271;
  display: flex;
  margin: auto;
  span {
    width: 30px;
    height: 24px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 24px;
    color: #ffb696;
    opacity: 1;
    margin: 0 5px;
  }
  div {
    width: 112px;
    height: 22px;
    background: #ffd2ac;
    opacity: 1;
  }
  div:first-of-type {
    opacity: 1;
  }
  div:nth-of-type(2) {
    opacity: 0.8;
  }
  div:nth-of-type(3) {
    opacity: 0.6;
  }
  div:nth-of-type(4) {
    opacity: 0.4;
  }
  div:nth-of-type(5) {
    opacity: 0.2;
  }
}
.tips {
  text-align: center;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffb696;
  opacity: 1;
  position: absolute;
  bottom: 57px;
  width: 440px;
  margin-left: 80px;
}

#card6 {
  font-size: 20px;
  .desc_title2 {
    width: 140px;
    text-align: right;
  }
  .desc_title3 {
    float: right;
    color: #ffdcbe;
    width: 71px;
    text-align: center;
  }
  .desc_values_width {
    display: inline-block;
    width: 141px;
    text-align: right;
  }
  .desc_values_width1 {
    display: inline-block;
    width: 68px;
    text-align: center;
  }
}

#chart1,
#chart1_2 {
  height: 247px;
  margin-bottom: 32px;
}
#chart2 {
  margin-top: 80px;
}
#chart2 {
  width: 100%;
  height: 400px;
}
#chart4 {
  width: 100%;
  height: 664px;
}
#chart5 {
  width: 100%;
  height: 664px;
}
#chart6 {
  width: 100%;
  height: 720px;
}

#card4 .title {
  margin-bottom: 30px;
}
#chart3_1,
#chart3_2,
#chart3_3 {
  margin-top: 10px;
  height: 300px;
  width: 100%;
}
#card6 .title .title_l {
  font-size: 30px;
  margin-top: 2px;
}
.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  color: #ffd2ac;

  .title_l {
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 28px;
    // color: #37393a;
    opacity: 1;
  }
  .title_1 {
    width: 341px;
    height: 45px;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 22px;
    // color: #4d4d4d;
    opacity: 1;
  }
  .title_r_1 {
    width: 122px;
    height: 32px;
    font-size: 23px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 36px;
    // color: #2d2d2d;
    opacity: 1;
    > span:nth-of-type(2) {
      margin: 0 16px;
    }
  }
}
#card3 .title,
#card6 .title {
  margin-bottom: 107px;
}
.desc {
  width: 100%;
  font-size: 26px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 48px;
  padding: 12px 7px;
  color: #ffdcbe;
  opacity: 1;
  background: #d52e38;
  border-radius: 12px;
  box-sizing: border-box;
  margin-bottom: 32px;
  box-shadow: 0px 4px 11px rgba(119, 72, 59, 0.16);
}

.desc_title_wrapper {
  overflow: hidden;
  font-size: 20px;
  .desc_title1 {
    float: left;
    color: #ffdcbe;
    margin-left: 112px;
  }
  .desc_title2 {
    float: right;
    color: #ffdcbe;
    margin-right: 2px;
  }
}
.desc_title_wrapper7 {
  overflow: hidden;
  color: #ffdcbe;
  font-size: 20px;
  width: 100%;
  .desc_title1 {
    float: left;
    color: #ffdcbe;
    width: 20%;
    text-align: left;
  }
  .desc_title2 {
    float: left;
    color: #ffdcbe;
    width: 20%;
    text-align: right;
  }
  .desc_title3 {
    float: left;
    width: 20%;
    text-align: right;
  }
}
#card7 {
  .title1 {
    position: absolute;
    top: 82px;
    left: 252px;
    font-size: 40px;
    color: #ffd2ac;
    font-weight: bold;
    text-align: center;
  }
  .text1 {
    width: 594px;
    position: absolute;
    top: 158px;
    left: 47px;
    font-size: 28px;
    color: #ffdcbe;
    line-height: 45px;
  }
  .line {
    width: 576px;
    border: 2px dashed #ffd2ac;
    position: absolute;
    top: 431px;
    left: 56px;
  }
  .title2 {
    position: absolute;
    top: 537px;
    left: 252px;
    font-size: 40px;
    color: #ffd2ac;
    font-weight: bold;
    text-align: center;
  }
  .text2 {
    width: 594px;
    position: absolute;
    top: 613px;
    left: 47px;
    font-size: 28px;
    color: #ffdcbe;
    line-height: 45px;
  }
}
#card8 {
  overflow: hidden;
  background: url("../../assets/img/bg-end.png") no-repeat center center;
  .logo {
    position: absolute;
    top: 581px;
    left: 124px;
    width: 495px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}

.desc_values_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  .desc_values_item {
    width: 100%;
    line-height: 35px;
    text-align: right;
    padding-right: 29px;
    color: #ffdcbe;
    font-size: 19px;
    line-height: 67px;
  }
}
.desc_values_wrapper7 {
  width: 100%;
  height: 100%;
  .desc_values_item7 {
    width: 100%;
    line-height: 72px;
    color: #ffdcbe;
  }
  .span_width {
    display: inline-block;
    text-align: right;
    font-size: 20px;
    width: 20%;
  }
  .span_width1 {
    display: inline-block;
    width: 20%;
    text-align: left;
    font-size: 24px;
  }
  .span_width2 {
    display: inline-block;
    width: 20%;
    text-align: right;
    font-size: 20px;
  }
}

#card6 .desc_values_item {
  line-height: 72px;
}

.card4_title {
  color: #000;
}
.card4_des {
  padding: 12px 14px;
  background: #dee4f7;
  color: #5766da;
  font-size: 14px;
  line-height: 24px;
}
.card5_aa,
.card5_qq {
  position: absolute;
  width: 60px;
  text-align: right;
  right: 4px;
  top: 0px;
  bottom: 0;
  p {
    height: 10%;
  }
}
.card5_aa {
  p {
    color: #676767;
  }
}
.card5_qq p span {
  margin-top: 16px;
  display: inline-block;
}
.card5_qq > p > span:first-of-type {
  width: 14px;
  height: 14px;
  vertical-align: top;
  overflow: hidden;
  margin-right: 4px;
  img {
    width: 100%;
    height: auto;
  }
}
.card7_des {
  line-height: 24px;
  font-size: 14px;
  padding: 12px 0;
  letter-spacing: 1px;
  span {
    color: #ff6600;
  }
}
.islu {
  color: #00b466;
}
.isred {
  color: #e54444;
}
</style>